@import "@campaign-portal/components-library/src/styles/alaris-global";
@import "tables";
@import "taiga";
@import "dimensions";
@import "largeText";
@import "charts";

:root {
	--as-gap:          1rem;
	--as-root-padding: 1.25rem;
}

.layout {
	display:         grid;
	align-items:     center;
	justify-content: center;
	height:          100vh;
	overflow-y:      auto;
	background-size: cover;
}

.alaris-content {
	height: calc(100% - var(--as-navbar-height) - 2rem);

	&.overflow {
		height: unset;
	}
}


alaris-tabs {
	.tabs {
		margin-left: 1rem;
	}
}

.cdk-dialog-container:focus-visible {
	outline: none;
}

.scroll-cdk-dialog {
	max-height: 90vh;
	overflow:   auto;

	&::-webkit-scrollbar {
		display: none;
	}
}

.reset {
	@extend %as-reset-element;
	color:      var(--as-TextPrimary);
	text-align: start;

}
